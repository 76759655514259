import { Carousel, ContainerFullWidth, ContainerMaxWidth } from '@hypersay/ui';
import { withPrefix } from 'gatsby';
import React from 'react';
import { Link } from '../Link';
import T from '../T';

import {
  TestimonialProps,
  testimonialsData,
} from '../../data/testimonialsData';

import * as styles from './Testimonials.module.scss';

const testimonialsRender = (data: TestimonialProps[]) => (
  <Carousel initial={1} interval={20000} autoScroll>
    {data.map(
      ({
        author,
        company,
        date,
        highlight,
        job,
        logo,
        text,
      }: TestimonialProps) => (
        <div className={styles.testimonialItem} key={JSON.stringify(highlight)}>
          <div>
            <h4>{highlight}</h4>
            <p className={styles.text}>
              <em>{text}</em>
            </p>
          </div>
          <div className={styles.creds}>
            <div className={styles.authorBox}>
              <div className={styles.author}>
                <strong>{author}</strong>
              </div>
              <div>
                <em>{job}</em>, {company}, {date}
              </div>
            </div>
            <img src={withPrefix(`/logos/${logo}`)} alt={company} />
          </div>
        </div>
      ),
    )}
  </Carousel>
);

const Testimonials = () => (
  <ContainerFullWidth>
    <ContainerMaxWidth className={styles.container}>
      <T tagName="h1" id="What Hypersay users say" />
      {testimonialsRender(testimonialsData)}
      <div className={styles.readMore}>
        <T
          tagName="div"
          id="read more about use cases in {education}, {events}, {business} and {workremotely}"
          values={{
            education: (
              <Link to="/blog/education/" className={styles.link}>
                <T id="Education" />
              </Link>
            ),
            events: (
              <Link to="/blog/events/" className={styles.link}>
                <T id="Events" />
              </Link>
            ),
            business: (
              <Link to="/blog/business/" className={styles.link}>
                <T id="Business" />
              </Link>
            ),
            workremotely: (
              <Link to="/blog/work-remotely/" className={styles.link}>
                <T id="Work remotely" />
              </Link>
            ),
          }}
        />
      </div>
    </ContainerMaxWidth>
  </ContainerFullWidth>
);

export default Testimonials;
