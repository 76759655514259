import { useSetOutline } from '@hypersay/ui';
import React from 'react';
import CTA from '../components/cta';
import Credentials from '../components/Homepage/Credentials';
import MainHero from '../components/Homepage/HeroRemotePresentations';
import HowHypersayWorks from '../components/Homepage/HowHypersayWorks';
import LiveStreaming from '../components/Homepage/LiveStreaming';
import SlidesButInteractive from '../components/Homepage/SlidesButInteractive';
// import SlimBanner from '../components/Homepage/SlimBanner';
// Disabled the Slim Banner but keep the component for future use
import Testimonials from '../components/Homepage/Testmonials';
import WhatIsAnInteraction from '../components/Homepage/WhatIsAnInteraction';
import SchemaOrg from '../components/schemaOrg';
import SEO from '../components/seo';
import Layout from '../layouts/default';

const IndexPage = () => {
  useSetOutline();
  return (
    <Layout>
      <SchemaOrg />
      <SEO
        title="Interactive presentations — Hypersay"
        description="Hypersay is a platform for live interactive presentations featuring live polls, live subtitles, q&a, feedback and analytics. Loved by lecturers, teachers, keynote speakers, training companies, businesses around the world."
        keywords={[
          'live presentation',
          'interactive presentation',
          'live interactive presentation',
          'live subtitles',
          'work remotely',
          'work from home',
          'remote presentations',
          'live polling',
          'live poll',
          'live quiz',
          'live slide sharing',
          'live voting',
          'presentation tools',
          'session analytics',
          'digital pulse',
          'ppt',
          'powerpoint',
          'slides',
          'google slides',
          'alternative to glisser',
          'alternative to polleverywhere',
          'alternative to beekast',
          'alternative to prezi',
        ]}
      />
      <MainHero />
      <Credentials />
      <LiveStreaming />
      <SlidesButInteractive />
      <WhatIsAnInteraction />
      <HowHypersayWorks />
      <Testimonials />
      <CTA />
    </Layout>
  );
};

export default IndexPage;
