import React, { useEffect, useState } from 'react';

import {
  Button,
  ContainerFullWidth,
  ContainerMaxWidth,
  Icons,
} from '@hypersay/ui';
import { withPrefix } from 'gatsby';
import {
  InteractionAnswers,
  interactionsData,
  InteractionsItems,
} from '../../data/homepageInteractionsData';
import { Link } from '../Link';
import T from '../T';
import * as styles from './SlidesButInteractive.module.scss';

const barColors = ['#b58900', '#cb4b16', '#d33682'];

const horizontalBars = (max: number, answers: InteractionAnswers[]) =>
  answers.map((answer, index) => {
    const width =
      answer.count === 0
        ? '2px'
        : `${Math.max(1, (answer.count * 100) / max)}%`;

    return (
      <div
        key={JSON.stringify(answer.text)}
        className={styles.bar}
        style={{
          background: `linear-gradient(90deg, ${barColors[index]} ${width}, #1e1e1e ${width})`,
          justifyContent: 'space-between',
        }}
      >
        <span className="label">{answer.text}</span>
        <span className="count">{answer.count}</span>
      </div>
    );
  });

const interactionsRender = (data: InteractionsItems[]) => {
  const [userAnswer, setUserAnswer] = useState<number | null>(null);
  const [chosenOne, setChosenOne] = useState<number | null>(null);
  useEffect(() => {
    const random = Math.floor(Math.random() * data.length);
    setChosenOne(random);
  }, []);
  if (chosenOne === null) {
    return null;
  }

  const { slide, question, answers } = data[chosenOne];

  const max = answers.reduce((acc, item) => acc + item.count, 0);
  const answersWithUser: InteractionAnswers[] = answers.map((a, i) => ({
    ...a,
    count: i === userAnswer ? a.count + 1 : a.count,
  }));

  return (
    <>
      <div className={styles.mockupDesktopContainer}>
        <img
          className={styles.backgroundDesktop}
          src={withPrefix('/images/mockupDesktop.png')}
          alt="mockup browser"
        />
        {/* Also add the label for "presenter" here */}
        <div className={styles.content}>
          <div style={{ padding: '.5em' }}>
            <h2 className={styles.question}>
              <span>{question}</span>
            </h2>
            {horizontalBars(max, answersWithUser)}
          </div>
          <img
            className={styles.slide}
            src={withPrefix(`/images/slides/${slide}`)}
            alt="current slide"
          />
        </div>
      </div>

      <div className={styles.mockupMobileContainer}>
        {/* Also add the label for "participant" here */}
        <img
          className={styles.backgroundMobile}
          src={withPrefix('/images/mockupMobile.png')}
          alt="mockup mobile"
        />
        <div className={styles.content}>
          <div style={{ padding: '.5em' }}>
            <h2 className={styles.question}>
              <span>{question}</span>
            </h2>
            {answers.map((answer, i) => (
              <Button
                onClick={() => {
                  setUserAnswer(i);
                }}
                theme="dark"
                variant={i === userAnswer ? 'primary' : undefined}
                outline={i !== userAnswer || false}
                label={answer.text}
                key={JSON.stringify(answer.text)}
                style={{
                  width: '100%',
                  marginTop: '.8em',
                  fontSize: '.8em',
                  padding: '.8em',
                }}
              />
            ))}
          </div>
          <img
            className={styles.slide}
            src={withPrefix(`/images/slides/${slide}`)}
            alt="current slide"
          />
        </div>
      </div>
    </>
  );
};

const SlidesButInteractive = () => (
  <ContainerFullWidth className={styles.container}>
    <ContainerMaxWidth className={styles.subContainer}>
      <div className={styles.mainText}>
        <h1>
          <T id="Your slides," />
          <br />
          <T id="but interactive" />
        </h1>
        <p className={styles.description}>
          <T id="Don’t stop at simply sharing the slides with your participants. Keep them engaged by adding live quizzes and other interactions." />
        </p>
        <div className={styles.bullets}>
          <div>
            <Icons.FancyBullet color="--pastel-red" />
            <T tagName="p" id="Live quizzes" />
          </div>
          <div>
            <Icons.FancyBullet color="--royal-purple" />
            <T tagName="p" id="Open questions" />
          </div>
          <div>
            <Icons.FancyBullet color="--spring-green" />
            <T tagName="p" id="Opinion scales" />
          </div>
          <div>
            <Icons.FancyBullet color="--bleu-de-france" />
            <T tagName="p" id="Interactive images" />
          </div>
        </div>
        <Link
          to="/live-presentations"
          className={styles.cta}
          track="HowToWorkRemotely-FromHomepage"
        >
          <T id="Read about live presentations" />
        </Link>
      </div>
      <div className={styles.mainShow}>
        {interactionsRender(interactionsData)}
        <div className={styles.tryThis}>
          <T id="👉Try this!" />
        </div>
      </div>
    </ContainerMaxWidth>
  </ContainerFullWidth>
);

export default SlidesButInteractive;
