import { Button, ContainerFullWidth, ContainerMaxWidth } from '@hypersay/ui';
import React from 'react';
import T from '../T';

import { withPrefix } from 'gatsby';
import { Link } from '../Link';
import * as styles from './HowHypersayWorks.module.scss';

const SvgAnalytics = () => (
  <svg viewBox="0 0 241 178">
    <title>{'analytics-chart'}</title>
    <g fill="none" fillRule="evenodd">
      <g fill="#DAB826" fillRule="nonzero">
        <path d="M0 .01v177l10-.01V.01z" />
        <path d="M241 167H1.008v10H241z" />
      </g>
      <g transform="translate(19 9.997)">
        <circle
          cx={59}
          cy={18}
          r={9}
          fill="#FDFEFD"
          className={styles.circle1}
        />
        <circle
          cx={84}
          cy={43}
          r={9}
          fill="#FDFEFD"
          className={styles.circle2}
        />
        <circle
          cx={109}
          cy={31}
          r={9}
          fill="#FDFEFD"
          className={styles.circle3}
        />
        <circle
          cx={134}
          cy={79}
          r={9}
          fill="#FDFEFD"
          className={styles.circle4}
        />
        <circle
          cx={159}
          cy={115}
          r={9}
          fill="#FDFEFD"
          className={styles.circle5}
        />
        <circle
          cx={184}
          cy={65}
          r={9}
          fill="#FDFEFD"
          className={styles.circle6}
        />
        <circle
          cx={209}
          cy={9}
          r={9}
          fill="#FDD387"
          className={styles.circle7}
        />
        <circle
          cx={34}
          cy={59}
          r={9}
          fill="#FDFEFD"
          className={styles.circle8}
        />
        <circle
          cx={9}
          cy={30}
          r={9}
          fill="#FDFEFD"
          className={styles.circle9}
        />
      </g>
    </g>
  </svg>
);

const BoxUpload = () => (
  <div className={`${styles.item}`}>
    <div className={`${styles.box} ${styles.upload}`}>
      <div className={styles.uploadBarContainer}>
        <T tagName="p" id="MySlides.pptx" />
        <div className={styles.uploadBar}>
          <span className={styles.runningBars} />
          <T id="Uploading..." />
        </div>
      </div>
      <div className={styles.text}>
        <T id="Upload" />
      </div>
    </div>
    <div className={styles.desc}>
      <T
        tagName="p"
        id="From your hard drive, upload your slide presentation into Hypersay."
      />
      <T tagName="p" id="We accept PowerPoint, Google Slides and PDF." />
    </div>
  </div>
);

const BoxInteractivity = () => (
  <div className={styles.item}>
    <div className={`${styles.box} ${styles.interactivity}`}>
      <div className={styles.interactivityButtonsContainer}>
        <Button
          size="m"
          label="to be"
          colors={['--splashed-white', '--dark-raspberry']}
          style={{ marginBottom: '.5em' }}
          className={styles.interactivityButton}
        />
        <Button
          size="m"
          label="not to be"
          colors={['--dark-raspberry', '--splashed-white']}
          className={styles.interactivityButton}
        />
        <img
          src={withPrefix('/svg/pointing-hand.svg')}
          style={{ position: 'absolute', top: '60px', right: '-15px' }}
          alt="pointer"
        />
      </div>
      <div className={styles.text}>
        <T id="Interactivity" />
      </div>
    </div>
    <div className={styles.desc}>
      <T
        tagName="p"
        id="Add polls, quizzes, questions, opinion scales, images and videos to your presentation."
      />
      <T tagName="p" id="Delight your audience." />
    </div>
  </div>
);

const BoxGoLive = () => (
  <div className={styles.item}>
    <div className={`${styles.box} ${styles.golive}`}>
      <div className={styles.studentGrid}>
        {[...Array(12)].map((item, index) => (
          <img
            src={withPrefix(`/images/p${index + 1}.png`)}
            alt={`participant ${index}`}
            title={item}
            key={`p${index + 1}.png`}
          />
        ))}
      </div>
      <div className={styles.text}>
        <T id="Go live" />
      </div>
    </div>
    <div className={styles.desc}>
      <T
        tagName="p"
        id="You are finally ready to go live. Send the unique code to your participants."
      />
      <T tagName="p" id="They can register with Hypersay or stay anonymous." />
    </div>
  </div>
);

const BoxAnalytics = () => (
  <div className={styles.item}>
    <div className={`${styles.box} ${styles.analytics}`}>
      <SvgAnalytics />
      <div className={styles.text}>
        <T id="Analytics" />
      </div>
    </div>
    <div className={styles.desc}>
      <T
        tagName="p"
        id="You session is a success. But how successful was it?"
      />
      <T
        tagName="p"
        id="Check analytics for the key metrics that make up the 'digital pulse' of your presentation."
      />
    </div>
  </div>
);

const HowHypersayWorks = () => {
  return (
    <ContainerFullWidth className={styles.container}>
      <ContainerMaxWidth>
        <T tagName="h1" id="How Hypersay works" />
        <div className={styles.stepsGrid}>
          <BoxUpload />
          <BoxInteractivity />
          <BoxGoLive />
          <BoxAnalytics />
        </div>
        <Link to="/blog/guide" className={styles.cta}>
          <T id="See Hypersay User Guide" />
        </Link>
      </ContainerMaxWidth>
    </ContainerFullWidth>
  );
};

export default HowHypersayWorks;
