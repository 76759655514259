import { Button, ContainerFullWidth } from '@hypersay/ui';
import React from 'react';
import navigateToAppSite from '../util/navigate-to-app-site';
import * as styles from './cta.module.scss';
import LoginModal from './LoginModal';
import T from './T';

const onDashboardClicked = () => navigateToAppSite();

const cta = () => (
  <ContainerFullWidth className={styles.container}>
    <T tagName="h2" id="Deliver presentations they will never forget" />
    <LoginModal
      onLoggedIn={navigateToAppSite}
      trigger={
        <Button
          size="m"
          label={<T id="Get started now. It's free!" />}
          variant="primary"
          theme="dark"
          className="visibleForNotSignedIn"
        />
      }
    />
    <Button
      size="m"
      label={<T id="Go to dashboard!" />}
      variant="primary"
      theme="dark"
      className="visibleForSignedIn"
      onClick={onDashboardClicked}
    />
    <p className="visibleForNotSignedIn">
      <T id="no credit card required" />
    </p>
  </ContainerFullWidth>
);

export default cta;
