import { ContainerFullWidth, ContainerMaxWidth, Icons } from '@hypersay/ui';
import { withPrefix } from 'gatsby';
import React from 'react';
import { Link } from '../Link';
import T from '../T';
import * as styles from './LiveStreaming.module.scss';

const LiveStreaming = () => {
  return (
    <ContainerFullWidth className={styles.container}>
      <ContainerMaxWidth className={styles.subContainer}>
        <div className={styles.left}>
          <img
            src={withPrefix('/images/live-streaming-waving-girl.png')}
            alt=""
          />
        </div>
        <div className={styles.right}>
          <h1>
            <T id="Live Streaming" />
          </h1>
          <T tagName="p" id="Working from home? Teaching remotely?" />
          <T
            tagName="p"
            id="Now Hypersay supports live audio/video streaming for presenters:"
          />
          <div className={styles.bullet} style={{ marginTop: '2em' }}>
            <Icons.MicFilled
              color="--texas-rose"
              size="l"
              className={styles.icon}
            />
            <T tagName="p" id="Audio only" />
          </div>
          <div className={styles.bullet}>
            <Icons.CameraFilled
              color="--pastel-red"
              size="l"
              className={styles.icon}
            />
            <T tagName="p" id="Video streaming" />
          </div>
          <div className={styles.bullet} style={{ alignItems: 'flex-start' }}>
            <Icons.HeadphonesFilled
              color="--splashed-white"
              size="l"
              className={styles.icon}
            />
            <div>
              <T tagName="p" id="Embed 3rd party stream" />
              <p style={{ opacity: 0.5 }}>
                <T id="from YouTube, Vimeo, Twitch, Restream." />
              </p>
            </div>
          </div>
          <Link
            to="/blog/work-remotely/"
            className={styles.cta}
            track="HowToWorkRemotely-FromHomepage"
          >
            <T id="See how to work remotely" />
          </Link>
        </div>
      </ContainerMaxWidth>
    </ContainerFullWidth>
  );
};

export default LiveStreaming;
