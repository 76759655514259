import React from 'react';
import Helmet from 'react-helmet';

export default React.memo(() => {
  const schema = [
    {
      // tslint:disable: quotemark
      '@context': 'https://schema.org',
      '@type': 'Product',
      aggregateRating: {
        itemReviewed: 'Hypersay',
        ratingCount: 12,
        reviewCount: 12,
        ratingExplanation:
          'These reviews & ratings are aggregated from Crozdesk and Capterra',
        ratingValue: 5,
      },
      audience: [
        { audienceType: 'teachers' },
        { audienceType: 'lecturers' },
        { audienceType: 'event organisers' },
        { audienceType: 'corporate' },
      ],
      description:
        'A fast-growing platform for interactive presentations used by 170k users in 200+ countries',
      name: 'Hypersay',
      url: 'https://hypersay.com',
      brand: {
        name: 'Hypersay',
      },
      logo: {
        '@type': 'ImageObject',
        url:
          'https://hypersay-static.s3-accelerate.amazonaws.com/0.23.0/android-chrome-256x256.png',
        width: '256px',
        height: '256px',
      },
      image:
        'https://hypersay-static.s3-accelerate.amazonaws.com/0.23.0/android-chrome-256x256.png',
      review: {
        reviewBody:
          // tslint:disable-next-line: max-line-length
          'Hypersay has given ‘life’ to our monthly Team Hangouts, where our senior managers discuss a range of topics. It has given a ‘voice’ to the employees and made it possible for the managers to get valuable feedback on specific questions. Half of the audience is in the same room as the presenter and the other half dial into the meeting from all over the world and Hypersay is the perfect platform for this purpose. The interactivity, the question wall and the fact that the employees can log in anonymously has added a positive dynamic to our monthly Team Hangouts.',
        author: 'Anne Kloster',
        reviewRating: {
          ratingValue: 5,
        },
      },
      // tslint:enable: quotemark
    },
  ];

  return (
    <Helmet>
      {/* Schema.org tags */}
      <script type="application/ld+json">{JSON.stringify(schema)}</script>
    </Helmet>
  );
});
