import * as React from 'react';
import T from '../components/T';

export interface InteractionAnswers {
  text: React.ReactElement;
  count: number;
}

export interface InteractionsItems {
  slide: string;
  question: React.ReactElement;
  answers: InteractionAnswers[];
}

export const interactionsData: InteractionsItems[] = [
  {
    slide: 'Slide2.jpg',
    question: <T id="Did Albert Einstein win the Nobel Prize?" />,
    answers: [
      {
        text: <T id="Yes, he did" />,
        count: 7,
      },
      {
        text: <T id="No" />,
        count: 2,
      },
      {
        text: <T id="Albert... who?" />,
        count: 4,
      },
    ],
  },
  {
    slide: 'Slide3.jpg',
    question: <T id="These two men have in common...?" />,
    answers: [
      {
        text: <T id="The same hairspray" />,
        count: 3,
      },
      {
        text: <T id="The same nationality" />,
        count: 1,
      },
      {
        text: <T id="The same mood" />,
        count: 7,
      },
    ],
  },
  {
    slide: 'Slide4.jpg',
    question: <T id="The country with most Cats is...?" />,
    answers: [
      {
        text: <T id="Russia" />,
        count: 3,
      },
      {
        text: <T id="America" />,
        count: 18,
      },
      {
        text: <T id="China" />,
        count: 6,
      },
    ],
  },
  {
    slide: 'Slide6.jpg',
    question: <T id="The heart beats over X times a day?" />,
    answers: [
      {
        text: <T id="10,000 times" />,
        count: 17,
      },
      {
        text: <T id="20,000 times" />,
        count: 8,
      },
      {
        text: <T id="100,000 times" />,
        count: 3,
      },
    ],
  },
  {
    slide: 'Slide7.jpg',
    question: <T id="How fast does your sneeze travel?" />,
    answers: [
      {
        text: <T id="Speed of sound" />,
        count: 2,
      },
      {
        text: <T id="50 mph" />,
        count: 4,
      },
      {
        text: <T id="100 mph" />,
        count: 8,
      },
    ],
  },
  {
    slide: 'Slide8.jpg',
    question: <T id="Can Astronauts in space cry?" />,
    answers: [
      {
        text: <T id="Yes" />,
        count: 12,
      },
      {
        text: <T id="No" />,
        count: 5,
      },
      {
        text: <T id="Don’t cry in space" />,
        count: 10,
      },
    ],
  },
  {
    slide: 'Slide9.jpg',
    question: <T id="Who invented scissors?" />,
    answers: [
      {
        text: <T id="Edward Scissors Hand" />,
        count: 5,
      },
      {
        text: <T id="Leonardo da Vinci" />,
        count: 7,
      },
      {
        text: <T id="The Scissor Sisters" />,
        count: 3,
      },
    ],
  },
];

export default interactionsData;
