import React from 'react';

import { ContainerFullWidth, ContainerMaxWidth, Icons } from '@hypersay/ui';
import { withPrefix } from 'gatsby';
import T from '../T';
import * as styles from './Credentials.module.scss';

const crozdeskAndCapterra = (
  <div className={styles.credsContainer}>
    <a
      className={styles.creds}
      href="https://www.capterra.co.uk/software/174870/hypersay"
      target="capterra"
      itemType="http://schema.org/Product"
    >
      <span className={styles.hidden} itemProp="name">
        Hypersay
      </span>
      <span
        className={styles.hidden}
        itemProp="aggregateRating"
        itemType="http://schema.org/AggregateRating"
      >
        <span itemProp="ratingValue">5</span>
        <span itemProp="reviewCount">12</span>
      </span>

      <Icons.StarFilled color="--urobilin" />
      <Icons.StarFilled color="--urobilin" />
      <Icons.StarFilled color="--urobilin" />
      <Icons.StarFilled color="--urobilin" />
      <Icons.StarFilled color="--urobilin" />
      <img
        src={withPrefix('/svg/logo-capterra.svg')}
        style={{ maxHeight: '20px' }}
        alt="logo capterra"
      />
    </a>
    <a
      className={styles.creds}
      href="https://crozdesk.com/communications/presentations-software/hypersay"
      target="crozdesk"
    >
      <Icons.StarFilled color="--urobilin" />
      <Icons.StarFilled color="--urobilin" />
      <Icons.StarFilled color="--urobilin" />
      <Icons.StarFilled color="--urobilin" />
      <Icons.StarFilled color="--urobilin" className="" />
      <img
        src={withPrefix('/svg/logo-crozdesk.svg')}
        style={{ maxHeight: '20px' }}
        alt="logo crozdesk"
      />
    </a>
  </div>
);

const Credentials = () => {
  return (
    <ContainerFullWidth className={styles.container}>
      <ContainerMaxWidth className={styles.subContainer}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'flex-start',
          }}
        >
          <div>
            <T
              id="Businesses and educational institutions in 150+ countries use Hypersay."
              tagName="p"
            />
            <T id="Help us spread the word." tagName="p" />
          </div>
          <a
            href="https://twitter.com/intent/tweet?url=https%3A%2F%2Fhypersay.com&text=Have%20you%20tried%20@hyper_say%20for%20remote%20presentations%3F&hashtags=workfromhome%2Cremotepresentations%2Clivepresentations"
            target="_blank"
            className={styles.tweetButton}
            rel="noopener noreferrer"
          >
            <Icons.LogoTwitter />
            <T id="Tweet about Hypersay" tagName="span" />
          </a>
        </div>
        <div>
          {crozdeskAndCapterra}
          <div className={styles.logosEverywhere}>
            <img
              src={withPrefix('/logos/logo-schneider.png')}
              alt="Schneider Electric"
            />
            <img
              src={withPrefix('/logos/logo-newcastle.svg')}
              alt="Newcastle University"
            />
            <img
              src={withPrefix('/logos/logo-erasmus2.png')}
              alt="Erasmus University Rotterdam, Netherlands"
            />
            <img
              src={withPrefix('/logos/logo-ucl.png')}
              alt="University College of London"
            />
            <img
              src={withPrefix('/logos/logo-birmingham.png')}
              alt="University of Birmingham"
            />
            <img
              src={withPrefix('/logos/logo-bordeaux.png')}
              alt="Universite de Bordeaux"
            />
            <img
              src={withPrefix('/logos/logo-via-denmark.png')}
              alt="Via University College, Denmark"
            />
            <img
              src={withPrefix('/logos/logo-bucharest.png')}
              alt="University of Bucharest, Romania"
            />
          </div>
        </div>
      </ContainerMaxWidth>
    </ContainerFullWidth>
  );
};

export default Credentials;
