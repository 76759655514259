import React from 'react';

import { ContainerFullWidth, ContainerMaxWidth } from '@hypersay/ui';
import { withPrefix } from 'gatsby';
import T from '../T';

import * as styles from './WhatIsAnInteraction.module.scss';

const WhatIsAnInteraction = () => {
  // let controls = false;
  // if (typeof window !== 'undefined' && 'connection' in navigator) {
  //   // @ts-ignore
  //   if (navigator.connection.saveData === true) {
  //     controls = true;
  //   }
  // }

  return (
    <ContainerFullWidth>
      <ContainerMaxWidth className={styles.container}>
        <T tagName="h1" id="What is a live presentation?" />
        {/* <div className={styles.gif}>
          <video autoPlay loop muted playsInline controls={controls}>
            <source
              src={withPrefix('/images/live-presentations.webm')}
              type="video/webm"
            />
            <source
              src={withPrefix('/images/live-presentations.mp4')}
              type="video/mp4"
            />
          </video>
        </div> */}
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/dDoxtthdmOo"
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
        <div style={{ marginBottom: '1em', marginTop: '1.5em' }}>
          <T tagName="p" id="Good presenters have great slides" />
          <T
            tagName="p"
            id="Great presenters know how to engage their audience."
          />
        </div>
        <div style={{ opacity: 0.7 }}>
          <T
            tagName="p"
            id="Hypersay helps you run live presentations by using QR codes to connect your audience and share your slides."
          />
          <T
            tagName="p"
            id="Use live polls, quizzes, questions and surveys keep your audience engaged."
          />
        </div>
      </ContainerMaxWidth>
    </ContainerFullWidth>
  );
};

export default WhatIsAnInteraction;
