import * as React from 'react';
import T from '../components/T';

export interface TestimonialProps {
  highlight: React.ReactElement;
  author: string;
  job: string;
  company: string;
  date: React.ReactElement;
  text: React.ReactElement;
  logo: string;
}

export const testimonialsData: TestimonialProps[] = [
  {
    highlight: <T id="Distant learning is not easy" />,
    author: 'Vittoria Volterrani',
    job: 'Teacher Trainer',
    company: 'Istituto Omnicomprensivo di Bobbio',
    date: <T id="December 2020" />,
    text: (
      <T id="Fast, user friendly and effective. I use Hypersay in teacher training and my participants always remain engaged and active till the end. Distant learning is not easy for everyone, especially for adults. With Hypersay and the different features provided, you can re-establish contact and give everyone a voice, while maintaining rhythm and participation during your presentation." />
    ),
    logo: 'logo-bobbio.jpg',
  },
  {
    highlight: <T id="Engaging online training" />,
    author: 'Darren Yehl',
    job: 'President',
    company: 'Cornerstone Training Institute',
    date: <T id="May 2020" />,
    text: (
      <T id="In response to Covid-19 Cornerstone switched to remote on-line training for Environmental Safety. Hypersay allowed us to keep our training engaging and interactive for our attendees. Hypersay’s reports section also provides us the necessary information to keep our training partners happy with our new on-line presence." />
    ),
    logo: 'logo-cornerstone.jpg',
  },
  {
    highlight: <T id="Hypersay energises Monthly TownHall Meetings" />,
    author: 'Anne Kloster',
    job: 'Executive Assistant, Digital Services & Software',
    company: 'Schneider Electric',
    date: <T id="Dec 2018" />,
    text: (
      <T id="Hypersay has given ‘life’ to our monthly Team Hangouts, where our senior managers discuss a range of topics. It has given a ‘voice’ to the employees and made it possible for the managers to get valuable feedback on specific questions. Half of the audience is in the same room as the presenter and the other half dial into the meeting from all over the world and Hypersay is the perfect platform for this purpose. The interactivity, the question wall and the fact that the employees can log in anonymously has added a positive dynamic to our monthly Team Hangouts." />
    ),
    logo: 'logo-schneider.png',
  },
  {
    highlight: <T id="Hypersay gives a voice to my students" />,
    author: 'Dr Karen Elliott',
    job: 'Lecturer in Enterprise & Innovation',
    company: 'Newcastle University, United Kingdom',
    date: <T id="Feb 2019" />,
    text: (
      <T id="A significant proportion of my international students who have voiced in the past their reluctance to ask questions and have a ‘voice’ during my lectures, can now interact using this technology because of the anonymous features. In addition, we can now have a non-threatening space in the lecture hall with student initiated dialogue via Hypersay." />
    ),
    logo: 'logo-newcastle.svg',
  },
  {
    highlight: <T id="Engage with Hypersay" />,
    author: 'Yannick Lung',
    job: 'Professor of Economics',
    company: 'University of Bordeaux, France',
    date: <T id="Oct 2018" />,
    text: (
      <T id="I used Hypersay during the last semester and I find it a very useful tool to integrate different kinds of elements (presentation, video, quiz, image, link) allowing online interactions with feedbacks from students. [...] I was waiting for such a pedagogical tool, and now it is here!" />
    ),
    logo: 'logo-bordeaux.png',
  },
  {
    highlight: <T id="No more student attention span deficit" />,
    author: 'Sandra Bar',
    job: 'Art Lecturer',
    company: 'University of Bucharest',
    date: <T id="April 2019" />,
    text: (
      <T id="Hypersay enhances the dynamics of my courses with polls and open questions displaying results in real time, as well as video content and links to further reading recommendations. My students follow the slides either on the classroom screen, or on their laptops and smartphones, so Hypersay is also a tool to fuel class engagement and to increase the attention span and focus." />
    ),
    logo: 'logo-bucharest.png',
  },
  {
    highlight: <T id="Going beyond Polls and Quizzes" />,
    author: 'Dr Cristina Sambrook',
    job: 'Director, BSc Business Management Programmes (Singapore)',
    company: 'University of Birmingham, UK',
    date: <T id="May 2019" />,
    text: (
      <T id="Last November (2018), I used Hypersay over an 8-week period to teach a Management and Leadership course in Singapore.  Even with 70 students in the classroom, the student engagement was excellent thanks to the interactivity within Hypersay.  But Hypersay goes beyond polls, quizzes and questions during a class.  The reports section provides me insight into what is happening by slide and by student.  These reports allow me to refine my slides and become more effective.  Best of all, the reports are available the minute I finish my lecture." />
    ),
    logo: 'logo-birmingham.png',
  },
  {
    highlight: <T id="Student discussions delivered efficiently" />,
    author: 'Lars Neilsen',
    job: 'Lecturer',
    company: 'VIA University, Denmark',
    date: <T id="June 2019" />,
    text: (
      <T id="I have been using Hypersay since August 2018 at three engineering classes at VIA University College in Denmark (approximately 80 students). The feedback has been very good. I am especially using the system to interact with students through questions and polls and they deliver answers, opinions etc. through the small groups that they are working in. This means that they get to discuss and deliver their thoughts in an efficient and for them satisfying way. At the end of the lectures I get feedback related to my teaching, the systems and the subject presented and that is without any doubt very beneficial for me as lecturer." />
    ),
    logo: 'logo-via-denmark.png',
  },
  {
    highlight: <T id="Engaged Events" />,
    author: 'Bhavesh Chandaria',
    job: 'Business Head',
    company: 'Safintra Rwanda Limited, SAFAL Group',
    date: <T id="April 2019" />,
    text: (
      <T id="Hypersay has surely made my talks more engaging.  I can use live polls/questions and collect audience feedback immediately.  This is done with such ease that it leads to better acceptance of what I have to say or present" />
    ),
    logo: 'logo-safal-group.png',
  },
];

export default testimonialsData;
