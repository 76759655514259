import React from 'react';
import { Tab, TabList, TabPanel, useTabState } from 'reakit';

import {
  Button,
  ContainerFullWidth,
  ContainerMaxWidth,
  Icons,
} from '@hypersay/ui';
import { withPrefix } from 'gatsby';
import { sendAndGo } from '../../util/ga';
import navigateToAppSite from '../../util/navigate-to-app-site';
import LoginModal from '../LoginModal';
import T from '../T';
import * as styles from './HeroRemotePresentations.module.scss';

const onDashboardClicked = () => navigateToAppSite();

const loginButtonOrDashboard = (
  <>
    <LoginModal
      onLoggedIn={navigateToAppSite}
      trigger={
        <Button
          label={<T id="Register for free" />}
          size="xl"
          variant="primary"
          theme="dark"
          // colors={['--athens-grey', '--coral']}
          className={`visibleForNotSignedIn ${styles.buttonShadow}`}
        />
      }
    />
    <Button
      label={<T id="Go to dashboard" />}
      size="xl"
      variant="primary"
      theme="dark"
      onClick={onDashboardClicked}
      className={`visibleForSignedIn ${styles.buttonShadow}`}
    />
  </>
);

const attendWebinarBUtton = (
  <Button
    label={<T id="Attend free webinar" />}
    size="xl"
    variant="primary"
    theme="dark"
    onClick={() =>
      sendAndGo(
        {
          category: 'navigateToWebinars',
          action: 'navigate',
          label: window.location.href,
        },
        '/webinars',
      )
    }
    colors={['--onyx', '--athens-grey']}
    className={styles.buttonShadow}
  />
);

const BadgeNew = () => (
  <div className={styles.newBadge}>
    <Icons.ZapFilled size="xs" color="--splashed-white" />
    <span>
      <T id="new" />
    </span>
  </div>
);

const HeroRemotePresentations = () => {
  const tab = useTabState({
    selectedId: 'chat',
  });

  return (
    <ContainerFullWidth className={styles.container}>
      <ContainerMaxWidth className={styles.content}>
        <div className={styles.mainFeatures}>
          <h1>
            <T id="Remote Presentations: Live & Interactive" />
          </h1>
          <TabList {...tab} aria-label="Main features">
            <Tab
              {...tab}
              stopId="slide-sharing"
              className="hs-button l glass withLabel light"
            >
              <Icons.LiveSlides color="--royal-purple" />
              <T id="Slide Sharing" tagName="span" />
            </Tab>
            <Tab
              {...tab}
              stopId="live-quizzes"
              className="hs-button l glass withLabel light"
            >
              <Icons.TouchFilled color="--allports" />
              <T id="Live Quizzes" tagName="span" />
            </Tab>
            <Tab
              {...tab}
              stopId="chat"
              className="hs-button l glass withLabel light"
            >
              <Icons.ChatFilled color="--coral" />
              <T id="Chat" tagName="span" />
              <BadgeNew />
            </Tab>
            <Tab
              {...tab}
              stopId="video-streaming"
              className="hs-button l glass withLabel light"
            >
              <Icons.CameraFilled color="--pastel-red" />
              <span className={styles.videoStreamingLabel}>
                <T id="Video Streaming" />
              </span>
              <BadgeNew />
            </Tab>
          </TabList>
        </div>
        <div className={styles.mainImage}>
          <TabPanel {...tab} stopId="slide-sharing">
            <img
              src={withPrefix('/images/tilted-slides@2400w.jpg')}
              alt="tablet showing Hypersay layout"
            />
          </TabPanel>
          <TabPanel {...tab} stopId="live-quizzes">
            <img
              src={withPrefix('/images/tilted-quiz@2400w.jpg')}
              alt="tablet showing Hypersay layout"
            />
          </TabPanel>
          <TabPanel {...tab} stopId="chat">
            <img
              src={withPrefix('/images/tilted-chat@2400w.jpg')}
              alt="tablet showing Hypersay chat"
            />
          </TabPanel>
          <TabPanel {...tab} stopId="video-streaming">
            <img
              src={withPrefix('/images/tilted-streaming@2400w.jpg')}
              alt="tablet showing Hypersay layout"
            />
          </TabPanel>
        </div>
        <div className={styles.callToActions}>
          {loginButtonOrDashboard}
          {attendWebinarBUtton}
        </div>
      </ContainerMaxWidth>
    </ContainerFullWidth>
  );
};

export default HeroRemotePresentations;
